export default function Caracteristicas(){
    return(
        <section className="flex w-[100vw] flex-col justify-center items-center font-primary my-10">
            <article className="hidden w-[100vw] bgCaracteristicas sm:flex justify-center">
                <div className="w-[1536px] h-24 flex justify-around items-center text-white font-semibold font-primary">
                    <div className="flex gap-1 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 32 32"><path fill="#F8F8F8" d="M16 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14m0-26a12 12 0 1 0 12 12A12 12 0 0 0 16 4"/><path fill="#F8F8F8" d="M20.59 22L15 16.41V7h2v8.58l5 5.01z"/></svg>
                        <h1 className="text-xl">Puntualidad</h1>
                    </div>
                    <div className="flex gap-1 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="#F8F8F8" d="M20 8h-5.612l1.123-3.367c.202-.608.1-1.282-.275-1.802S14.253 2 13.612 2H12c-.297 0-.578.132-.769.36L6.531 8H4c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h13.307a2.01 2.01 0 0 0 1.873-1.298l2.757-7.351A1 1 0 0 0 22 12v-2c0-1.103-.897-2-2-2M4 10h2v9H4zm16 1.819L17.307 19H8V9.362L12.468 4h1.146l-1.562 4.683A.998.998 0 0 0 13 10h7z"/></svg>
                        <h1 className="text-xl">Confianza</h1>
                    </div>
                    <div className="flex gap-1 items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="#F8F8F8" d="m12 15.39l-3.76 2.27l.99-4.28l-3.32-2.88l4.38-.37L12 6.09l1.71 4.04l4.38.37l-3.32 2.88l.99 4.28M22 9.24l-7.19-.61L12 2L9.19 8.63L2 9.24l5.45 4.73L5.82 21L12 17.27L18.18 21l-1.64-7.03z"/></svg>
                        <h1 className="text-xl">Excelencia</h1>
                    </div>
                </div>
            </article>
            <article className="max-w-[1536px] w-[100%] grid sm:grid-cols-3 gap-8 lg:gap-0 place-items-center text-center font-primary">
                    <div className="sm:hidden bg-[#4DBC66] w-[100%] h-[5rem] text-white flex gap-1 items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 32 32"><path fill="#F8F8F8" d="M16 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14m0-26a12 12 0 1 0 12 12A12 12 0 0 0 16 4"/><path fill="#F8F8F8" d="M20.59 22L15 16.41V7h2v8.58l5 5.01z"/></svg>
                        <h1 className="text-xl">Puntualidad</h1>
                    </div>
                    <div className="sm:w-[15rem] sm:h-[15rem] mx-5 sm:mx-0 lg:h-[10rem] lg:w-[20rem] bg-[#F9F9F9] p-5 shadow-lg -translate-y-12 sm:-translate-y-4">
                        <p className="text-balance">Nuestro equipo de limpieza estar&aacute; puntualmente presente en el horario acordado, incluso algunos minutos antes, para llevar a cabo sus tareas programadas.</p>
                    </div>
                    <div className="sm:hidden bg-[#E08600] w-[100%] h-[5rem] text-white flex gap-1 items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="#F8F8F8" d="M20 8h-5.612l1.123-3.367c.202-.608.1-1.282-.275-1.802S14.253 2 13.612 2H12c-.297 0-.578.132-.769.36L6.531 8H4c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h13.307a2.01 2.01 0 0 0 1.873-1.298l2.757-7.351A1 1 0 0 0 22 12v-2c0-1.103-.897-2-2-2M4 10h2v9H4zm16 1.819L17.307 19H8V9.362L12.468 4h1.146l-1.562 4.683A.998.998 0 0 0 13 10h7z"/></svg>
                        <h1 className="text-xl">Confianza</h1>
                    </div>
                    <div className="sm:w-[15rem] sm:h-[15rem] mx-5 sm:mx-0 lg:h-[10rem] lg:w-[20rem] bg-[#F9F9F9] p-5 shadow-lg -translate-y-12 sm:-translate-y-4">
                        <p className="text-balance">Proporcionamos una relaci&oacute;n de confianza para que puedas dedicarte a tus actividades, mientras nosotros nos encargamos de la limpieza y organizaci&oacute;n de tus espacios.</p>
                    </div>
                    <div className="sm:hidden bg-[#3D87DD] w-[100%] h-[5rem] text-white flex gap-1 items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="#F8F8F8" d="m12 15.39l-3.76 2.27l.99-4.28l-3.32-2.88l4.38-.37L12 6.09l1.71 4.04l4.38.37l-3.32 2.88l.99 4.28M22 9.24l-7.19-.61L12 2L9.19 8.63L2 9.24l5.45 4.73L5.82 21L12 17.27L18.18 21l-1.64-7.03z"/></svg>
                        <h1 className="text-xl">Excelencia</h1>
                    </div>
                    <div className="sm:w-[15rem] sm:h-[15rem] mx-5 sm:mx-0 lg:h-[10rem] lg:w-[20rem] bg-[#F9F9F9] p-5 shadow-lg -translate-y-12 sm:-translate-y-4">
                        <p className="text-balance">Buscamos tener la mejor calidad de los empleados encargados, del desarrollo de la limpieza y del material empleado en el trabajo a realizar.</p>
                    </div>
            </article>
        </section>
    )
}