import desinfeccion from "./Imagenes/desinfectar-casa.webp"

const data = [
    {
        title:"CALIDAD",
        description:"Revisamos de manera continua la calidad de los servicios que ofrecemos, asegurando así una experiencia completa para nuestros usuarios."
    },
    {
        title:"CAPACIDAD",
        description:"Disponemos de expertos debidamente capacitados que aseguran el éxito en cada labor de limpieza y servicio que proporcionamos."
    },
    {
        title:"EXPERIENCIA",
        description:"Nuestra pericia, capacidad de adaptación en el servicio, velocidad y eficiencia contribuyen a que disfrutes de la mejor satisfacción."
    },
    {
        title:"SERVICIO INTEGRAL",
        description:"Nos comprometemos a ofrecerte el servicio que requieres en el momento preciso. Disfruta de servicios respaldados por garantía."
    },
]

export default function Cualidades(){
    return(
        <section className="max-w-[1536px] w-[100%] overflow-hidden flex flex-col gap-10 font-primary py-10 px-8">
            <div className="text-center text-[#3B3B3B] sm:px-8 flex flex-col gap-10">
                <h1 className="text-2xl sm:text-3xl font-semibold">¿POR QUÉ NOS RECOMIENDAN?</h1>
                <p className="text-md sm:text-lg text-balance">Garantizamos que nuestros servicios satisfacen los niveles de calidad, proporcionando así una experiencia excepcional. Agradecemos la confianza de nuestros clientes y el reconocimiento de nuestra eficiencia, derivado del compromiso de todo nuestro equipo.</p>
            </div>
            <article className="flex justify-center items-center my-8">
                <div className=" flex flex-wrap items-center justify-center gap-20">
                    {data.map((item, index) => (
                        <div key={index} className="md:w-64 xl:w-72 flex flex-col gap-2 text-center md:text-left">
                            <div className="flex items-center gap-2 justify-center md:justify-normal">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48"><g fill="none" stroke="#4DBC66" strokeLinejoin="round" strokeWidth="4"><path d="M24 44a19.937 19.937 0 0 0 14.142-5.858A19.937 19.937 0 0 0 44 24a19.938 19.938 0 0 0-5.858-14.142A19.937 19.937 0 0 0 24 4A19.938 19.938 0 0 0 9.858 9.858A19.938 19.938 0 0 0 4 24a19.937 19.937 0 0 0 5.858 14.142A19.938 19.938 0 0 0 24 44Z"/><path strokeLinecap="round" d="m16 24l6 6l12-12"/></g></svg>
                                <h2 className="text-lg font-semibold text-[#3B3B3B]">{item.title}</h2>
                            </div> 
                            <p className="text-balance xl:text-lg text-[#3B3B3B]">{item.description}</p>
                        </div>
                    ))}
                </div>
                <img src={desinfeccion} alt="persona barriendo el suelo" className="hidden lg:flex w-[40%] h-[30rem] rounded-2xl"/>
            </article>
        </section>
    )
}