import BannerNosotros from "./Banner";
import Clientes from "./Clientes";
import MisionVision from "./Mision";
import Sucursales from "./Sucursales";

export default function Nosotros(){
    return(
        <main className="flex flex-col items-center overflow-hidden">
            <BannerNosotros/>
            <MisionVision/>
            <Sucursales/>
            <Clientes/>
        </main>
    )
}