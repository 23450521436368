import { Link, Outlet, useLocation } from "react-router-dom"
import logo from ".//../Imagenes/logo.webp"
import { useState } from "react"

export default function Header() {
    const location = useLocation();
    const active = location.pathname
    const [open, setOpen] = useState(false)
    return (
        <>
            <header className="flex justify-between bg-[rgb(232,206,121,80%)] absolute w-[100%] px-5 sm:px-10 py-5 items-center z-50">
                <div className="w-40 sm:w-52">
                    <img src={logo} alt="logo 5DMayab" />
                </div>

                {/* navbar mobile */}
                <nav>
                    <button onClick={() => setOpen(!open)} data-collapse-toggle="navbar-sticky" type="button" className="inline-flex sm:hidden items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg hover:border-2 focus:outline-none dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="#3B3B3B" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    {open &&
                        <div className="flex sm:hidden rounded-bl-3xl fixed shadow-lg w-36 flex-col h-[10rem] inset-y-0 right-0 z-10 bg-[#E8CE79] px-6 py-6 sm:ring-1 sm:ring-gray-900/10 items-end">
                                <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700 flex justify-end" onClick={() => setOpen(!open)}>
                                    <span className="sr-only">Close menu</span>
                                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            <div className="mt-6 flow-root ">
                                <div className="-my-6 divide-y divide-gray-500/10">
                                    <div className="space-y-2 py-4 font-primary text-lg flex flex-col text-[#3B3B3B] font-medium overflow-hidden items-end ">
                                        <Link to="/"><h3>Inicio</h3></Link>
                                        <Link to="/Nosotros"><h3>Nosotros</h3></Link>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </nav>

                {/* navbar desktop */}
                <nav className="hidden sm:flex overflow-hidden gap-4 text-[#3B3B3B] font-medium text-xl font-primary">
                    <Link to="/" className={`${ active=== '/' ? 'underline-offset-4 underline' : ''}`}><h3>Inicio</h3></Link>
                    <Link to="/Nosotros" className={`${active === '/Nosotros' ? 'underline-offset-4 underline' : ''}`}><h3>Nosotros</h3></Link>
                </nav>
            </header>
            <Outlet />
        </>
    )
}