import Banner from "./Banner";
import Servicios from "./Servicios";
import Cualidades from "./Cualidades";
import Caracteristicas from "./Caracteristicas";
import FooterBanner from "./footerBanner";
import BannerSwiper from "./Swipper";

export default function Home(){
    return(
        <main className="flex flex-col items-center overflow-hidden">
            <Banner/>
            <BannerSwiper/>
            <Servicios/>
            <Cualidades/>
            <Caracteristicas/>
            <FooterBanner/>
        </main>
    )
}