import mision from "./Imagenes/mision.webp"

export default function MisionVision(){
    return(
        <>
        <div className="w-[10%] bg-[#E08600] h-1 my-10"></div>
        <section className="grid lg:grid-cols-2 xl:grid-cols-3 mb-10">
            <aside className="mision lg:col-span-1 xl:col-span-2 hidden lg:flex">
                <img src={mision} alt="mujer limpiando escritorio" />
            </aside>
            <article className=" font-primary flex flex-wrap flex-col justify-around gap-10 items-center">
                <div className="p-5 gap-5 flex flex-col shadow-xl w-[18rem] sm:w-[30rem]">
                    <div className="flex items-center justify-between ">
                        <h2 className="text-[#3C7CC7] font-semibold text-3xl md:text-5xl">Misi&oacute;n</h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 36 36"><path fill="#3C7CC7" d="M26.54 18a19.38 19.38 0 0 0-.43-4h3.6a12.3 12.3 0 0 0-.67-1.6h-3.35a19.72 19.72 0 0 0-2.89-5.87a12.3 12.3 0 0 0-2.55-.76a17.83 17.83 0 0 1 3.89 6.59h-5.39V5.6h-1.5v6.77h-5.39a17.83 17.83 0 0 1 3.9-6.6a12.28 12.28 0 0 0-2.54.75a19.72 19.72 0 0 0-2.91 5.85H6.94A12.3 12.3 0 0 0 6.26 14h3.63a19.38 19.38 0 0 0-.43 4a19.67 19.67 0 0 0 .5 4.37H6.42A12.34 12.34 0 0 0 7.16 24h3.23a19.32 19.32 0 0 0 2.69 5.36a12.28 12.28 0 0 0 2.61.79A17.91 17.91 0 0 1 12 24h5.26v6.34h1.5V24H24a17.9 17.9 0 0 1-3.7 6.15a12.28 12.28 0 0 0 2.62-.81A19.32 19.32 0 0 0 25.61 24h3.2a12.34 12.34 0 0 0 .74-1.6H26a19.67 19.67 0 0 0 .54-4.4m-9.29 4.37h-5.74a17.69 17.69 0 0 1-.09-8.4h5.83Zm7.24 0h-5.74V14h5.83a18.21 18.21 0 0 1 .42 4a18.12 18.12 0 0 1-.51 4.37" className="clr-i-outline clr-i-outline-path-1"/><path fill="#3C7CC7" d="M18 2a16 16 0 1 0 16 16A16 16 0 0 0 18 2m0 30a14 14 0 1 1 14-14a14 14 0 0 1-14 14" className="clr-i-outline clr-i-outline-path-2"/><path fill="none" d="M0 0h36v36H0z"/></svg>
                    </div>
                    <p className="text-[#575757] text-pretty text-lg sm:text-xl font-semibold">Buscamos brindar un servicio de limpieza de alta calidad a nuestros clientes, proporcion&aacute;ndolo de manera eficaz y honesta para lograr una completa satisfacci&oacute;n y lealtad a la empresa.</p>
                </div>
                <div className=" p-5 w-[18rem] sm:w-[30rem] gap-5 flex flex-col shadow-xl">
                    <div className="flex items-center justify-between">
                        <h2 className="text-[#E08600] font-semibold text-3xl md:text-5xl">Visi&oacute;n</h2>
                        <svg xmlns="http://www.w3.org/2000/svg" width="3.5em" height="3.5em" viewBox="0 0 256 256"><path fill="#E08600" d="M233 147.24L191.43 52.6a6 6 0 0 0-1.25-1.83a30 30 0 0 0-42.42 0A6 6 0 0 0 146 55v27h-36V55a6 6 0 0 0-1.76-4.25a30 30 0 0 0-42.42 0a6 6 0 0 0-1.25 1.83L23 147.24A46 46 0 1 0 110 168V94h36v74a46 46 0 1 0 87-20.76M64 202a34 34 0 1 1 34-34a34 34 0 0 1-34 34m0-80a45.77 45.77 0 0 0-18.55 3.92l29.61-67.38A18 18 0 0 1 98 57.71V137a45.89 45.89 0 0 0-34-15m94-64.28a18 18 0 0 1 22.94.83l29.61 67.37A45.9 45.9 0 0 0 158 137ZM192 202a34 34 0 1 1 34-34a34 34 0 0 1-34 34"/></svg>
                    </div>
                    <p className="text-[#575757] text-pretty text-lg sm:text-xl font-semibold">Ser la corporaci&oacute;n l&iacute;der en servicios de limpieza integrales en el sureste con una s&oacute;lida infraestructura operativa y tecnol&oacute;gica.</p>
                </div>
            </article>
        </section>
        </>
    )
}