export default function Banner(){
    return(
        <section className="banner w-[100%] overflow-hidden grid py-5 sm:py-10 px-10 sm:px-20 md:grid-cols-2 lg:gap-20">
            <div className="relative flex flex-col justify-center gap-5 px-5 sm:px-10 py-5 sm:py-10 h-[30rem] lg:h-[28rem] top-36 lg:top-38 bg-[rgb(217,217,217,80%)] rounded-2xl max-w-[38rem]">
                <h1 className="font-bold text-xl sm:text-3xl lg:text-4xl text-[#414141] font-primary text-pretty text-center sm:text-left">BIENVENIDOS A 5D MAYAB. EMPRESA LIDER EN LIMPIEZA CORPORATIVA</h1>
                <p className="font-primary text-lg md:text-xl text-[#414141] text-pretty text-center sm:text-left">Somos un empresa comprometida con brindar servicios de calidad y profesionalismo.</p>
                <a aria-label="link to whatsApp" target="_blank" href="https://wa.me/529831249266" className="text-center text-white font-primary shadow-md bg-[rgb(77,188,102)] p-4 rounded-xl text-sm md:text-lg">Contactanos v&iacute;a WhatsApp</a>
            </div>
        </section>
    )
}