import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation } from 'swiper/modules';
import { Pagination } from 'swiper/modules';

export default function BannerSwiper(){
    return(
        <Swiper navigation={true}
        modules={[Navigation, Pagination]} 
        pagination={{
            dynamicBullets: true,
        }}
        className="mySwiper">
          <SwiperSlide>
            <div className='swiper1 grid md:grid-cols-2'>
                <div className='font-primary flex flex-col items-center gap-8 col-start-2 justify-center md:justify-normal md:relative top-36 right-10 px-20 md:px-5  max-w-[48rem]'>
                        <h1 className='text-white text-2xl sm:text-4xl md:text-5xl font-semibold text-center'>LIMPIO <span className='text-[#4DBC66]'>& ORDENADO</span></h1>
                        <p className='md:text-[#3B3B3B]  text-md sm:text-xl text-center text-pretty'>Nos especializamos en la limpieza, el mantenimiento, la conservaci&oacute;n de empresas, domicilios, instituciones  y hoteles.</p>
                    <a href="#servicios" className='bg-[#E08600] text-white rounded-xl px-10 py-3'>VER SERVICIOS</a>
                </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='swiper2 grid md:grid-cols-2'>
                <div className='font-primary flex flex-col items-center gap-8 col-start-2 justify-center md:justify-normal md:relative max-w-[48rem] top-36 right-10 px-20 md:px-5'>
                        <h1 className='text-white text-2xl sm:text-4xl md:text-5xl font-semibold text-center'>LIMPIEZA <span className='text-[#3D87DD]'>COMERCIAL</span></h1>
                        <p className='text-white text-md sm:text-xl text-center text-pretty'>Somos profesionales en la limpieza de tus espacios de manera r&aacute;pida, eficiente y en horario no laboral, priorizando la calidad en cada servicio.</p>
                    <a  aria-label="link to whatsApp" target="_blank" href="https://wa.me/529831249266" className='bg-[#4DBC66] text-white rounded-xl px-10 py-3'>RESERVAR</a>
                </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className='swiper3 grid md:grid-cols-2'>
                <div className='font-primary flex flex-col items-center gap-8 col-start-2 justify-center max-w-[48rem] md:justify-normal md:relative top-36 right-10 px-20 md:px-5'>
                        <h1 className='text-white text-2xl sm:text-4xl md:text-5xl font-semibold text-center'>LIMPIEZA PROFUNDA <span className='text-[#E08600]'>EN APARTAMENTOS Y CASAS</span></h1>
                        <p className='text-white text-md sm:text-xl text-center text-pretty'>Nos enfocamos en los detalles y nos apasionan los cambios reflejados en un antes y un después.</p>
                    <a  aria-label="link to whatsApp" target="_blank" href="https://wa.me/529831249266" className='bg-[#4DBC66] text-white rounded-xl px-10 py-3'>RESERVAR</a>
                </div>
            </div>
          </SwiperSlide>
        </Swiper>
    )
}