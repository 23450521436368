import './App.css';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import Header from './Components/header';
import Home from './Components/Inicio/Home';
import Nosotros from './Components/Nosotros/Nosotros';
import Layout from './Layout';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Header/>}>
      <Route path="/" element={<Home/>} />
      <Route path="/Nosotros" element={<Nosotros/>} />
    </Route>
  )
)

function App() {
  return(
    <>
      <Layout>
        <RouterProvider router={router}/>
      </Layout> 
     </>
  )
}

export default App;
