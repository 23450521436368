import logo from ".//../Imagenes/logo.webp"

const data = [
    {
        name:"Chetumal",
        address:"Av. Ignacio Zaragoza No.353, Col. Centro",
    },
    {
        name:"Cancún",
        address:"Av. Tankah Super Manzana 24, Manzana 1, No. 74 Local 4",
    },
    {
        name:"Mérida",
        address:"Calle 20 No. 235, Plaza Luxus, local 209, Col. Altabrisa",
    },
]
export default function Footer(){
    return(
        <section className="p-10 bg-[#E8CE79] max-w-full font-primary text-[#3B3B3B] flex justify-around items-center">
            <div className="md:flex justify-around items-center w-[1536px]">
                <div className="flex justify-center mb-10 md:mb-0">
                    <img src={logo} alt="logo 5dmayab" className="w-60"/>
                </div>
                <article className="flex flex-col items-center justify-center gap-8">
                    <div className="flex flex-wrap gap-8 text-center text-balance justify-center">
                        {data.map((item, index) => (
                            <div key={index} className="flex flex-col gap-2 w-[15rem]">
                                <h3 className="font-semibold text-lg">{item.name}</h3>
                                <h3>{item.address}</h3>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2">
                        <div className="flex gap-1 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="3B3B3B" d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 5l-8-5zm0 12H4V8l8 5l8-5z"/></svg>
                            <p>5dmayaboperativo@gmail.com</p>
                        </div>
                        <div className="flex gap-1 items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="3B3B3B" d="M20 15.5c-1.2 0-2.5-.2-3.6-.6h-.3c-.3 0-.5.1-.7.3l-2.2 2.2c-2.8-1.5-5.2-3.8-6.6-6.6l2.2-2.2c.3-.3.4-.7.2-1c-.3-1.1-.5-2.4-.5-3.6c0-.5-.5-1-1-1H4c-.5 0-1 .5-1 1c0 9.4 7.6 17 17 17c.5 0 1-.5 1-1v-3.5c0-.5-.5-1-1-1M5 5h1.5c.1.9.3 1.8.5 2.6L5.8 8.8C5.4 7.6 5.1 6.3 5 5m14 14c-1.3-.1-2.6-.4-3.8-.8l1.2-1.2c.8.2 1.7.4 2.6.4z"/></svg>
                            <p>+52 983 124 9266</p>
                        </div>
                    </div>
                </article>
            </div>
        </section>
    )
}