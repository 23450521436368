import cancun from './Imagenes/cancun.webp'
import chetumal from './Imagenes/chetumal.webp'
import chetumalOtro from './Imagenes/Merida.webp'

const data = [
    {
        img:`${chetumal}`,
        name: "Chetumal",
        address: "Av. Ignacio Zaragoza No.353, Col. Centro",
    },
    {
        img:`${cancun}`,
        name: "Cancún",
        address: "Av. Tankah Super Manzana 24, Manzana 1, No. 74 Local 4",
    },
    {
        img:`${chetumalOtro}`,
        name: "Mérida",
        address: "Calle 20 No. 235, Plaza Luxus, local 209, Col. Altabrisa",
    },
]

export default function Sucursales() {
    return (
        <section className="bg-[#F2F2F2] flex flex-col py-8 font-primary w-[100%] overflow-hidden justify-center items-center">
            <div className="flex flex-col items-center my-10">
                <h1 className="text-[#3B3B3B] text-2xl sm:text-3xl font-semibold">SUCURSALES</h1>
                <div className="w-20 h-1 rounded-sm bg-[#E08600] my-3"></div>
            </div>
            <article className="max-w-[1536px] w-[100%] overflow-hidden flex flex-wrap items-center justify-center gap-16 sm:gap-28 text-pretty mb-10 px-10">
                {data.map((item, index) => (
                    <div key={index} className="flex flex-col gap-2 w-[15rem] items-center">
                        <div className='w-full h-full object-cover'>
                            <img src={item.img} alt="sucursal" className='w-[20rem] h-[12rem] rounded-xl' />
                        </div>
                        <h3 className="font-semibold text-lg text-[#575757]">{item.name}</h3>
                        <h3 className="text-center text-[#3B3B3B]">{item.address}</h3>
                    </div>
                ))}
            </article>
        </section>
    )
}