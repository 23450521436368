import React from "react";
import Footer from "./Components/Footer";

export default function Layout({children}){
    return(
        <>
        <main>{children}</main>
        <Footer/>
        </>
    )
}