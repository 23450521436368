import empresarial from "./Imagenes/empresarial.webp"
import hoteleria from "./Imagenes/hoteleria.webp"
import muebles from "./Imagenes/muebles.webp"
import airbnb from "./Imagenes/airbnb.webp"
import domestica from "./Imagenes/aspirador.webp"
import decks from "./Imagenes/decks.webp"

const data = [
    {
        title:"Limpieza empresarial",
        description:"Manten tus espacios laborales limpios, con nuestros servicios de limpieza corporativa especializada para oficinas, edificios y plazas comerciales.",
        img:`${empresarial}`
    },
    {
        title:"Limpieza de hotelería",
        description:"Limpieza y acomodo de las habitaciones e instalaciones, garantizando la calidad hotelera en todo momento y manteniendo profesionalismo con los huespedes.",
        img:`${hoteleria}`
    },
    {
        title:"Limpieza de muebles y colchones",
        description:"Lavado, aspirado, protección y desinfección a profundidad de colchones, muebles, alfombras y salas.",
        img:`${muebles}`
    },
    {
        title:"Limpieza de Airbnb",
        description:"Nos encargamos de dejar impecables tus espacios en renta, tal como tu cliente lo merece.",
        img:`${airbnb}`
    },
    {
        title:"Limpieza doméstica",
        description:"Te ayudamos a mantener tu hogar limpio y ordenado de manera segura, confiable y profesional, adaptándonos a todas tus necesidades.",
        img:`${domestica}`
    },
    {
        title:"Decks de madera",
        description:"Suministro, instalación, reconstrucción, mantenimiento y limpieza  a decks de madera, terrazas y pérgolas de madera.",
        img:`${decks}`
    },
]

export default function Servicios(){
    return(
        <section id="servicios" className="bg-[#F2F2F2] flex flex-col py-8 font-primary w-[100%] overflow-hidden justify-center items-center">
            <div className="flex flex-col items-center my-10">
                <h1 className="text-[#3B3B3B] text-2xl sm:text-3xl font-semibold">NUESTROS SERVICIOS</h1>
                <div className="w-20 h-1 rounded-sm bg-[#E08600] my-3"></div>
            </div>
            <article className="max-w-[1536px] w-[100%] overflow-hidden flex flex-wrap items-center justify-center gap-24 sm:gap-10 text-pretty mb-10">
                {data.map((item, index)=>(
                    <div key={index} className="flex justify-center items-center">
                        <img className="absolute w-72 sm:w-[28rem] rounded-xl h-[28rem] sm:h-[24rem]" src={item.img} alt='limpieza'/>
                        <div className="relative w-80 sm:w-[28rem] h-[24rem]  gap-10 flex flex-col items-center p-10 justify-center">
                            <div className="text-center flex flex-col gap-5 bg-[rgb(244,242,242,88%)] p-5 rounded-xl ">
                                <h3 className="text-[#3B3B3B] text-xl sm:text-2xl font-semibold">{item.title}</h3>
                                <p className="text-[#3B3B3B] text-balance">{item.description}</p>
                            </div>
                            <a aria-label="link to whatsApp" target="_blank" href="https://wa.me/529831249266"  className="bg-[rgb(77,188,102)] px-16 py-3 text-md rounded-2xl text-center text-white">RESERVAR</a>
                        </div>
                    </div>
                ))}
            </article>
        </section>
    )
}