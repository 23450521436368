import ine from './Imagenes/ine.webp'
import iepac from './Imagenes/iepac.webp'
import jumex from './Imagenes/jumex.webp'
import qroo from './Imagenes/qroo.webp'
import sema from './Imagenes/SEMA.webp'
import kia from './Imagenes/Kia-logo.webp'
import dgb from './Imagenes/dgb.webp'
import cobay from './Imagenes/cobay.webp'
import conalep from './Imagenes/conalep.webp'
import chevrolet from './Imagenes/chevrolet.webp'
import tomza from './Imagenes/Tomza.webp'
import ran from './Imagenes/ran.webp'
import exploran from './Imagenes/explorean.webp'
import fiestaInn from './Imagenes/fiestaInn.webp'
import suprema from './Imagenes/supremaCorte.webp'
import vivo from './Imagenes/vivo.webp'
import toyota from './Imagenes/toyota.webp'
import real from './Imagenes/realBahia.webp'
import sct from './Imagenes/sct.webp'
import frisa from './Imagenes/frisa.webp'

export default function Clientes(){
    return(
        <section className="flex flex-col py-8 font-primary w-[100%] overflow-hidden justify-center items-center">
        <div className="flex flex-col items-center my-10">
            <h1 className="text-[#3B3B3B] text-2xl sm:text-3xl font-semibold">NUESTROS CLIENTES</h1>
        </div>
        <article className="max-w-[1536px] w-[100%] overflow-hidden flex flex-wrap items-center justify-center gap-16 sm:gap-10 text-pretty mb-10 px-10">
            <img src={ine} alt="ine" className='w-[20rem]'/>
            <img src={iepac} alt="iepac" className='w-[20rem]'/>
            <img src={jumex} alt="jumex" className='w-[20rem]'/>
            <img src={qroo} alt="qroo" className='w-[20rem]'/>
            <img src={sema} alt="sema" className='w-[20rem]'/>
            <img src={kia} alt="kia" className='w-[20rem]'/>
            <img src={dgb} alt="dgb" className='w-[20rem]'/>
            <img src={cobay} alt="cobay" className='w-[20rem]'/>
            <img src={conalep} alt="conalep" className='w-[20rem]'/>
            <img src={chevrolet} alt="chevrolet" className='w-[20rem]'/>
            <img src={sct} alt="sct" className='w-[20rem]'/>
            <img src={tomza} alt="tomza" className='w-[20rem]'/>
            <img src={ran} alt="ran" className='w-[20rem]'/>
            <img src={exploran} alt="exploran" className='w-[20rem]'/>
            <img src={fiestaInn} alt="fiestaInn" className='w-[20rem]'/>
            <img src={suprema} alt="suprema" className='w-[20rem]'/>
            <img src={vivo} alt="vivo" className='w-[20rem]'/>
            <img src={toyota} alt="toyota" className='w-[20rem]'/>
            <img src={real} alt="real" className='w-[20rem]'/>
            <img src={frisa} alt="frisa" className='w-[20rem]'/>
        </article>
    </section>
    )
}